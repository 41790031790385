$cera-pro-path: 'https://assets.smooved.be/fonts/cera-pro' !default;
$font-family-cera-pro: 'CeraPro';
$use-font-cera-pro: $font-family-cera-pro, Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Thin.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Thin.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Thin.woff2') format('woff2'), url('#{$cera-pro-path}/Cera-Pro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Thin-Italic.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Thin-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Thin-Italic.woff2') format('woff2'),
        url('#{$cera-pro-path}/Cera-Pro-Thin-Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Light.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Light.woff2') format('woff2'), url('#{$cera-pro-path}/Cera-Pro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Light-Italic.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Light-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Light-Italic.woff2') format('woff2'),
        url('#{$cera-pro-path}/Cera-Pro-Light-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Regular.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Regular.woff2') format('woff2'), url('#{$cera-pro-path}/Cera-Pro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Regular-Italic.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Regular-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Regular-Italic.woff2') format('woff2'),
        url('#{$cera-pro-path}/Cera-Pro-Regular-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Medium.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Medium.woff2') format('woff2'), url('#{$cera-pro-path}/Cera-Pro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Medium-Italic.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Medium-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Medium-Italic.woff2') format('woff2'),
        url('#{$cera-pro-path}/Cera-Pro-Medium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Bold.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Bold.woff2') format('woff2'), url('#{$cera-pro-path}/Cera-Pro-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Bold-Italic.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Bold-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Bold-Italic.woff2') format('woff2'),
        url('#{$cera-pro-path}/Cera-Pro-Bold-Italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Black.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Black.woff2') format('woff2'), url('#{$cera-pro-path}/Cera-Pro-Black.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-family-cera-pro;
    src: url('#{$cera-pro-path}/Cera-Pro-Black-Italic.eot');
    src: url('#{$cera-pro-path}/Cera-Pro-Black-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$cera-pro-path}/Cera-Pro-Black-Italic.woff2') format('woff2'),
        url('#{$cera-pro-path}/Cera-Pro-Black-Italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
