//----------------------------------------
// Utils : Flex
//----------------------------------------

@import '../base/mixins';

.u-flex {
    display: flex;
}

.u-inline-flex-column {
    display: inline-flex !important;
    flex-direction: column !important;
}

.u-flex-column {
    display: flex !important;
    flex-direction: column !important;
}

.u-flex-row {
    display: flex !important;
    flex-direction: row !important;
}

.u-inline-flex-row {
    display: inline-flex !important;
    flex-direction: row !important;
}

.u-flex-responsive {
    display: flex;

    @include up-to-and-including-phone-landscape {
        flex-direction: column;
    }

    @include tablet-portrait-up {
        flex-direction: row;
    }
}

.u-flex-responsive-tablet-landscape-up {
    display: flex;

    @include up-to-and-including-tablet-portrait {
        flex-direction: column;
    }

    @include tablet-landscape-up {
        flex-direction: row;
    }
}

.u-flex-responsive-desktop-up {
    display: flex;
    flex-direction: column;

    @include desktop-up {
        flex-direction: row;
    }
}

.u-flex-responsive-inverse {
    display: flex;

    @include up-to-and-including-phone-landscape {
        flex-direction: row;
    }

    @include tablet-portrait-up {
        flex-direction: column;
    }
}

.u-flex-gap-column {
    column-gap: 1rem;
}

.u-flex-gap {
    gap: 1rem;
}

.u-flex-align-items-start {
    align-items: flex-start !important;
}

.u-flex-align-items-end {
    align-items: flex-end !important;
}

.u-flex-align-items-center {
    align-items: center !important;
}

.u-flex-align-items-baseline {
    align-items: baseline !important;
}

.u-flex-align-items-center-tablet-portrait-up {
    @include tablet-portrait-up {
        align-items: center !important;
    }
}

.u-flex-align-items-stretch {
    align-items: stretch !important;
}

.u-flex-align-self-end {
    align-self: end !important;
}

.u-flex-align-self-center {
    align-self: center !important;
}

.u-flex-justify-self-end {
    justify-self: end !important;
}

.u-flex-justify-content-center {
    justify-content: center !important;
}

.u-flex-justify-content-center-phone-landscape-up {
    @include phone-landscape-up {
        justify-content: center !important;
    }
}

.u-flex-justify-content-center-up-to-and-including-phone-landscape {
    @include up-to-and-including-phone-landscape {
        justify-content: center !important;
    }
}

.u-flex-justify-content-space-between {
    justify-content: space-between !important;
}

.u-flex-justify-content-space-around {
    justify-content: space-around;
}

.u-flex-justify-content-end {
    justify-content: end !important;
}

.u-flex-justify-content-flex-end {
    justify-content: flex-end !important;
}

.u-flex-justify-content-flex-start {
    justify-content: flex-start !important;
}

.u-flex-1 {
    flex: 1 1 auto !important;
}

.u-flex-one-third {
    flex: 1 1 33.3333% !important;
}

.u-flex-one-half {
    flex: 1 1 50% !important;
}

.u-flex-1-0-auto {
    flex: 1 0 auto !important;
}

.u-flex-1-0-20p {
    flex: 1 0 20% !important;
}

.u-flex-1-0-60p {
    flex: 1 0 60% !important;
}

.u-flex-1-1-0 {
    flex: 1 1 0 !important;
}

.u-flex-no-shrink {
    flex-shrink: 0 !important;
}

.u-flex-wrap {
    flex-wrap: wrap !important;
}

.u-flex-grow-1 {
    flex-grow: 1;
}

.u-flex-row-reverse,
.u-flex-row.u-flex-reverse {
    flex-direction: row-reverse !important;
    justify-content: flex-end;
}

.u-flex-column-reverse,
.u-flex-column.u-flex-reverse {
    flex-direction: column-reverse !important;
    justify-content: flex-end;
}

.u-mobile-flex-align-items-start {
    @include up-to-and-including-phone-landscape {
        align-items: flex-start !important;
    }
}
