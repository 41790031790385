.m-color-bullet {
    margin-left: var(--spacer-double);
    white-space: nowrap;
    overflow: visible;
    position: relative;

    &:before {
        content: '';
        background-color: var(--bullet-color);
        width: $bullet-size;
        height: $bullet-size;
        border-radius: 50%;
        transform: translateX(calc(-1 * var(--spacer-default)));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.u-flex-column > .m-color-bullet {
    margin-left: 0;
    margin-bottom: var(--spacer-default);
}

:root {
    --bullet-size: #{$bullet-size};
}
