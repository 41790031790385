.m-autocomplete-input {
    display: inline-block;

    .ng-autocomplete {
        width: 100%;
    }

    .autocomplete-container {
        box-shadow: none;
        width: 100%;
        height: auto;
        color: $color-text;

        .not-found {
            position: absolute;
            width: 100%;
            background: transparent;
            height: auto;
        }

        .input-container input {
            border: 1px solid $color-gray-light;
            border-radius: 4px;
            padding: 0 .75em 0 .75em;
            height: 46px;
            line-height: 46px;
            font-size: inherit;
            color: inherit;
            background: transparent;
        }

        .suggestions-container {

            top: 50px;
            border-radius: 4px;

            ul li {
                color: inherit;
                font-size: 14px;

                &:not(:last-of-type) {
                    border-bottom: $common-border-light;
                }

                > * {
                    padding: .5em .75em .5em .75em;
                }
            }
        }
    }

}
