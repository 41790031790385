$svg-icon-collection: (
    chevron-right:
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none' /%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z' fill='currentColor' /%3E%3C/svg%3E",
);

%svg-icon {
    display: block;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
}

@each $svg, $value in $svg-icon-collection {
    %svg-#{$svg} {
        @extend %svg-icon;
        background-image: url($value);
    }
}

@each $svg, $value in $svg-icon-collection {
    .u-svg-#{$svg} {
        @extend %svg-#{$svg};
    }
}

.u-icon-24 {
    width: 24px;
    height: 24px;
}
