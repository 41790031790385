@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

//--------------------------
//  SMOOVED Branding colors
//--------------------------

$color-brand-1: #7cdbd5;
$color-brand-2: #02c8a7;
$color-brand-3: #00887a;
$color-brand-3-light: #b2dcd7;
$color-brand-3-lighter: #d9edeb;
$color-brand-3-lightest: #70d5cf26;
$color-brand-4: #045056;
$color-brand-5: #04282b;

//--------------------------
//  BASE colors
//--------------------------

$color-white: #fff !default;
$color-black: #000 !default;

$color-gray-ultralight: #f5f7f7 !default;
$color-gray-lightest: #ecf0f0 !default;
$color-gray-light: #c3cfd0 !default;
$color-gray: #8a9a9b !default;
$color-gray-dark: #62787a;
$color-gray-darkest: #345154;
$color-gray-ultradark: #132b2e;

$color-gray-medium-light: rgba(0, 0, 0, 0.12) !default;
$color-gray-medium: #f8f8ff !default;

$color-gold: #f9be02 !default;
$color-silver: #c3cfd0 !default;
$color-bronze: #a27f5b !default;

$color-accent: #f9be02 !default;
$color-accent-light: #feecb3 !default;
$color-accent-extra-light: #f3f8f9 !default;
$color-accent-dark: darken(#f9be02, 20%) !default;

$color-storm-green-light: #a2c4cb !default;

$color-warning: $color-accent !default;
$color-warning-light: $color-accent-light !default;
$color-attention: #f9be02 !default;

$color-error: #f53240 !default;
$color-error-light: #fcc2c6 !default;
$color-error-dark: #80181f !default;
$color-error-background: #fdf3f4 !default;

$color-success: #00887a !default;
$color-success-light: #02c8a7 !default;
$color-success-dark: #045056 !default;
$color-success-background: #e6f2f1 !default;

$color-info-darkest: #050561 !default;
$color-info-dark: #6666ff !default;
$color-info: #d3d3ff !default;
$color-info-light: #ececff !default;

$color-disabled: #c5c5c5 !default;
$color-text: $color-brand-5 !default;
$color-link: #91c146 !default;
$color-link-hover: #45a049 !default;

$color-card-success: #f0faf9 !default;
$color-card-success-border: #d5f2f0 !default;
$color-card-success-icon: #37c8a7 !default;

$color-facebook: #1778f2;

$color-banner: #fff4e3;

$common-border-color: $color-gray !default;
$common-border-light-color: $color-gray-light !default;
$common-border-lightest-color: $color-gray-lightest !default;

$material-checkbox-checked-background-color: $color-brand-4 !default;
$material-radio-border-color: $color-brand-4 !default;
$material-radio-background-color: $color-brand-4 !default;

$material-button-toggle-checked-text-color: $color-white !default;
$material-button-toggle-checked-background-color: $color-gray !default;
$material-button-toggle-checked-border-color: $color-gray !default;
$material-button-toggle-standard-border-color: $common-border-light-color !default;
$material-button-toggle-standard-text-color: $color-text !default;
$material-button-toggle-standard-background-color: $color-gray !default;

$material-calendar-selected-background-color: $color-gray !default;
$material-tab-nav-background-color: $color-brand-1 !default;

$material-slide-toggle-thumb: $color-accent !default;
$material-slide-toggle-bar: rgba($color-accent, 0.3) !default;

$tag-success-background: $color-brand-3-light !default;
$tag-success-color: $color-brand-5 !default;
$tag-info-background: $color-gray-light !default;
$tag-info-color: $color-brand-5 !default;
$tag-error-background: $color-error-light !default;
$tag-error-color: $color-error-dark !default;

$top-review-bg-color: #f3f8f9;

$medal-gold: $color-gold;
$medal-silver: $color-silver;
$medal-bronze: $color-bronze;

$text-muted: #c3cfd0 !default;

$housematch-primary: #ed6b57;
$housematch-secondary: #400042;

//--------------------------
//  HOUSEMATCH Branding colors
//--------------------------

$color-terracota: #ed6b57;
$color-aubergine: #400042;
