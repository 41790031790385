//----------------------------------------
// Utils : Color
//----------------------------------------
@import '../base/variables';

@each $color, $value in map-merge($colors, $context-colors) {
    .u-color-#{'' + $color} {
        color: #{$value} !important;
    }

    .u-border-#{'' + $color} {
        border-color: #{$value};
        border-width: 1px;
        border-style: solid;
    }
}

:root {
    @each $color, $value in map-merge($colors, $context-colors) {
        --color-#{'' + $color}: #{$value};
    }
}

.u-filter-grayscale {
    filter: grayscale(100%);
}

.u-color-inherit {
    color: inherit;
}

.u-color-text-muted {
    color: $text-muted;
}
