@import '../base/variables';

$positive-background: $color-brand-3-lighter;
$positive-color: $color-brand-4;

$negative-background: #fde1e3;
$negative-color: $color-error-dark;

.m-highlight-sentiment {
    padding: 2px 4px;

    &--positive {
        background: $positive-background;
        color: $positive-color;
    }

    &--negative {
        background: $negative-background;
        color: $negative-color;
    }
}
