@import '../base/variables';
@import '../base/mixins';

%mat-form-field-w-auto {
    .mat-mdc-form-field-flex {
        width: auto;
    }

    .mat-mdc-select-value {
        max-width: unset;
    }

    .mat-mdc-form-field-infix {
        width: auto;
    }
}

.mat-mdc-select-arrow.mat-mdc-select-arrow {
    color: inherit;

    svg {
        display: none;
    }
}

.mdc-line-ripple {
    display: none;
}

.mat-mdc-select-trigger.mat-mdc-select-trigger {
    min-height: 44px;
    line-height: 44px;
}

.mat-mdc-select-trigger > div {
    display: flex;
    height: 100%;
    align-items: center;
}

.mat-mdc-form-field.mat-mdc-form-field {
    &.mat-focused.mat-focused {
        .mat-mdc-select-arrow {
            color: $color-text;
        }
    }
}

.mat-mdc-select-value {
    color: inherit;
}

ui-dropdown-input.u-w-auto .mat-mdc-form-field,
smvd-ui-dropdown-input.u-w-auto .mat-mdc-form-field {
    @extend %mat-form-field-w-auto;
}

.mat-mdc-form-field.ui-dropdown-input-appearance-fill {
    @extend %mat-form-field-w-auto;

    .mat-mdc-select-value {
        color: inherit;
    }

    .mat-mdc-select-value div,
    .mat-mdc-select-placeholder {
        text-decoration: underline;
    }

    .mat-mdc-form-field-wrapper {
        padding: 0;
    }

    .mat-mdc-select-trigger {
        min-height: unset;
    }

    .mat-mdc-select-arrow-wrapper {
        padding-left: var(--spacer-sm);
    }
}

.mat-mdc-select-arrow-wrapper.mat-mdc-select-arrow-wrapper {
    padding-left: var(--spacer-sm);

    & > .mat-mdc-select-arrow {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0 var(--spacer-xs) var(--spacer-xs);
        border-top: 0;
        border-left: 0;
        border-right: 2px;
        border-bottom: 2px;
        border-color: inherit;
        border-style: solid;
        transform: rotate(45deg);
    }
}

ui-dropdown-input,
smvd-ui-dropdown-input {
    &.u-color-primary {
        @include headings {
            color: var(--color-primary);
        }
    }

    &.u-color-secondary {
        @include headings {
            color: var(--color-secondary);
        }
    }

    &.u-color-tertiary {
        @include headings {
            color: var(--color-tertiary);
        }
    }
}
