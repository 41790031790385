@import "../base/variables";

$other-collection: (
    sidebar-width-open: $sidebar-width-open,
);

:root {
    @each $key, $value in $other-collection {
        --#{$key}: #{$value};
    }
}