@import 'projects/ui/src/lib/scss/base/variables';
@import 'projects/ui/src/lib/scss/base/mixins';

$action-buttons-height: 4.375rem;
$modal-header-size: 4rem;
$max-modal-height: calc(100vh - #{$modal-header-size} - 1.5rem); // 100vh - headerSize - marginBottom
$max-modal-height-inner-model: calc(100vh - #{$modal-header-size} - 1.5rem - 4rem); // 100vh - headerSize - marginBottom - innerSize
$max-modal-height-with-action-buttons: calc(
    100vh - #{$modal-header-size} - 1.5rem - #{$action-buttons-height}
); // 100vh - headerSize - marginBottom - actionButtonsSize

.mat-mdc-dialog-container.mat-mdc-dialog-container {
    @include overflowYAuto;
    padding: 0;
    border-radius: $common-border-radius;
    box-shadow: $common-box-shadow-dark;

    @include tablet-portrait-up {
        border-radius: $common-border-radius-large;
        overflow: hidden;
    }

    mat-dialog-content {
        max-height: #{$max-modal-height};
        padding: 0;
        margin: 0;
    }

    .mdc-dialog__content,
    .mdc-dialog__title {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        letter-spacing: inherit;
        color: inherit;
    }
}

.cdk-overlay-pane {
    &:not(.__modal--no-padding) {
        app-closable-modal-template {
            .__closable-modal__main {
                padding: var(--spacer-md) var(--spacer-double) var(--spacer-double);
            }
        }
    }

    &.__modal--action-buttons {
        .mat-mdc-dialog-container.mat-mdc-dialog-container mat-dialog-content {
            max-height: #{$max-modal-height-with-action-buttons};
            margin-bottom: #{$action-buttons-height};
        }
    }
}

app-two-panel-modal,
app-tri-panel-modal {
    & > * {
        overflow: hidden;
        max-height: 100%;
        display: block;
    }

    .__left-actions .mat-mdc-button-base {
        display: flex;

        & + .mat-mdc-button-base {
            margin-left: 0;
            margin-top: var(--spacer-default);
        }
    }

    .__closable-modal__main {
        padding: 0;
    }

    app-loading-container {
        max-height: 100%;
        overflow: hidden;
    }
}

.__two-panel-modal__main,
.__tri-panel-modal__main {
    max-height: #{$max-modal-height-inner-model};
    grid-template-rows: 1fr;
}

.__mobile-modal {
    &.__mobile-modal-full-screen {
        max-width: none !important;
        width: 100vw;
        height: 100vh;

        .mat-mdc-dialog-container.mat-mdc-dialog-container {
            max-height: none !important;
            padding: 0;
            border-radius: 0;
        }
    }

    &.u-background-white app-mobile-modal {
        background-color: var(--color-white);
    }

    &.__modal--action-buttons {
        .__main {
            margin-bottom: #{$action-buttons-height};
        }
    }
}

.__mobile-modal-padding {
    app-mobile-modal > main {
        padding-left: var(--spacer-default);
        padding-right: var(--spacer-default);
    }
}

// MODAL SIZES
%modal-big {
    width: $modal-big-width;
    max-width: 100%;
}

%modal-large {
    width: $modal-large-width;
    max-width: 100%;
}

%modal-medium {
    width: $modal-medium-width !important;
    max-width: 100% !important;
}

.__modal-md {
    @extend %modal-medium;
}

.__modal-lg {
    @extend %modal-large;
}

.__modal-xl {
    @extend %modal-big;
}

.__modal-two-panel,
.__modal-tri-panel {
    .__closable-modal__main {
        padding: 0;
    }
}
