@import '../base/variables';

$opacity: (
  transaparant: $opacity-transparant,
  semi-transparant: $opacity-semi-transparant,
  half-transparant: $opacity-half-transparant,
  semi-opaque: $opacity-semi-opaque,
  opaque: $opacity-opaque
);

@each $level, $value in $opacity {
    .u-opacity-#{'' + $level} {
        color: #{$value} !important;
    }
}

:root {
    @each $level, $value in $opacity {
        --opacity-#{'' + $level}: #{$value};
    }
}
