@import '../base/mixins';

$positions: (
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
);

.u-position-relative {
    position: relative;
}

.u-position-absolute {
    position: absolute !important;
}

.u-position-fixed {
    position: fixed;
}

.u-position-fixed-bottom-right {
    position: fixed !important;
    z-index: 9999;
    bottom: 2rem;
    right: 2rem;
}

@each $key, $value in $positions {
    .u-position-#{$key} {
        position: absolute;
        #{$key}: #{$value};
    }

    .u-position-fixed-#{$key} {
        position: fixed;
        #{$key}: #{$value};
    }
}

.u-bordered {
    border: $common-border-light;
    border-radius: $common-border-radius;
}

.u-border-none {
    border: none !important;
}

.u-border-radius-top-extra-large {
    border-radius: $common-border-radius-extra-large $common-border-radius-extra-large 0 0 !important;
}

.u-border-radius {
    border-radius: $common-border-radius !important;
}

.u-border-radius-large {
    border-radius: $common-border-radius-large !important;
}

.u-box-shadow {
    box-shadow: $common-box-shadow;
}

.u-bordered-no-radius {
    border: $common-border-light;
}

.u-bordered-top {
    border-top: $common-border-light;
}

.u-bordered-right {
    border-right: $common-border-light;
}

.u-bordered-bottom {
    border-bottom: $common-border-light;
}

.u-bordered-bottom-ultralight {
    border-bottom: 2px solid var(--color-gray-ultralight);
}

.u-bordered-bottom-ultralight {
    border-bottom: 1px solid var(--color-gray-ultralight);
}

.u-bordered-left {
    border-left: $common-border-light;
}

.u-bordered-lightest {
    border: $common-border-lightest;
}

.u-bordered-lightest-bottom {
    border-bottom: $common-border-lightest;
}

.u-bordered-ultralight-bottom {
    border-bottom: $common-border-lightest;
    border-color: $color-gray-ultralight;
    border-width: 2px;
}

.u-divider {
    border-bottom: 1px solid $divider-color;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
}

.u-divider-tablet-portrait-up {
    @include tablet-portrait-up {
        border-bottom: 1px solid $divider-color;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

.u-container {
    width: 100%;
    max-width: $container-max-width;
}

.u-container-extra-small {
    width: 100%;
    max-width: $container-extra-small-max-width;
}

.u-container-extra-extra-small {
    width: 100%;
    max-width: $container-extra-extra-small-max-width;
}

.u-container-semi-small {
    width: 100%;
    max-width: $container-semi-small-max-width;
}

.u-container-small {
    width: 100%;
    max-width: $container-small-max-width;
}

.u-container-medium {
    width: 100%;
    max-width: $container-medium-max-width;
}

.u-container-lg {
    width: 100%;
    max-width: $container-large-max-width;
}

.u-container-big {
    width: 100%;
    max-width: $container-big-max-width;
}

.u-container-deprecated {
    width: 100vw;
    max-width: 1200px;
    padding: ($spacer * 2) $spacer $spacer $spacer;
    margin: 0 auto;

    @include tablet-portrait-up {
        padding: $spacer;
        width: calc(100vw - #{$spacer});
    }
}

.u-hidden {
    visibility: hidden !important;
}

.u-display-none {
    display: none !important;
}

.u-display-inline {
    display: inline !important;
}

.u-display-inline-block {
    display: inline-block !important;
}

.u-display-inline-flex {
    display: inline-flex !important;
}

.u-display-block {
    display: block !important;
}

.u-display-block-up-to-and-including-phone-landscape {
    @include up-to-and-including-phone-landscape {
        display: block !important;
    }
}

.u-display-table {
    display: table;
}

.u-display-table-row {
    display: table-row;
}

.u-display-table-cell {
    display: table-cell;
}

.u-hide-up-to-and-including-phone-landscape {
    @include up-to-and-including-phone-landscape {
        display: none !important;
    }
}

.u-hide-up-to-and-including-tablet-portrait {
    @include up-to-and-including-tablet-portrait {
        display: none !important;
    }
}

.u-hide-tablet-portrait-up {
    @include tablet-portrait-up {
        display: none !important;
    }
}

.u-hide-tablet-landscape-up {
    @include tablet-landscape-up {
        display: none !important;
    }
}

.u-hide-up-to-and-including-tablet-landscape {
    @include up-to-and-including-tablet-landscape {
        display: none !important;
    }
}

.u-opacity-30 {
    opacity: 0.3;
}

.u-opacity-50 {
    opacity: 0.5;
}

.u-pointer-events-none {
    pointer-events: none;
}

:root {
    --border-radius-filter: 240px;
}

.u-border-radius-filter {
    border-radius: var(--border-radius-filter);
}
