@import '../base/variables';
@import '../base/mixins';

.mat-bottom-sheet-container.__smvd-ui-bottom-sheet {
    position: relative;
    border-radius: $common-border-radius-extra-large $common-border-radius-extra-large 0 0;
    padding: var(--spacer-default) var(--spacer-double);
    min-height: $bottom-sheet-min-height;
    overflow: visible;
}

@each $color, $value in map-merge($colors, $context-colors) {
    .mat-bottom-sheet-container.ui-bottom-sheet-#{'' + $color} {
        background-color: #{$value} !important;
    }
}

.mat-bottom-sheet-corner {
    position: absolute !important;
    top: 10vh;
    max-width: 90vw !important;

    .mat-bottom-sheet-container {
        min-width: auto;
        border-radius: var(--border-radius-lg);
    }

    @media (min-width: $mat-bottom-sheet-toggle-responsive-boundary) {
        position: absolute !important;
        bottom: 1rem;
        right: 1rem;
        top: auto;
        width: auto;
    }
}

.mat-bottom-sheet-backdrop-corner {
    background: rgba(19, 43, 46, 0.3);
    @media (min-width: $mat-bottom-sheet-toggle-responsive-boundary) {
        background: transparent;
    }
}
