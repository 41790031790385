@import 'variables';
@import 'mixins';

html {
    box-sizing: border-box;
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    @include bodyText;
    @include overflowYAuto;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
button,
textarea,
select,
input {
    &:focus {
        outline: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    @include title;
}

h1,
.h1 {
    font-size: $font-size-xxl;
}

h2,
.h2 {
    font-size: $font-size-xl;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    @include h5;
}

h6,
.h6 {
    @include h6;
}

p {
    margin: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

label {
    color: $color-text;
    font-weight: 400;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

fieldset {
    @extend .h6;
    border: none;
    padding: var(--spacer-sm) 0 0;
    color: var(--color-muted);
}

app-icon + span {
    display: inline;
    vertical-align: middle;
}

:root {
    --icon-size-default: 18px;
    --icon-size-sm: 12px;
    --icon-size-md: 24px;
}
