//----------------------------------------
// Utils : Background
//----------------------------------------
@import "../base/variables";

.u-background-circle-gray {
    background: $color-gray;
    color: $color-white;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 0.875rem;
    min-height: 24px;
    min-width: 24px;
    svg {
        width: 18px;
    }
}

.u-background-circle-gold {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $color-gold;
}

.u-background-circle-silver {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $color-silver;
}

.u-background-circle-bronze {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $color-bronze;
}

.u-background-circle-none {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $color-black;
}


@each $color, $value in map-merge($colors, $context-colors) {
    .u-background-#{'' + $color} {
        background-color: #{$value} !important;
    }
}

:root {
    --background-color-hover: #{$color-gray-medium};
}

.u-background-blurred {
    position: relative;

    &:after {
        content: ' ';
        background: linear-gradient(to top, $color-white, transparent);
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1.5rem;
    }
}
