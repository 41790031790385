@import '../base/mixins';

$size: (-sm: '38px', -md: '44px', -xl: '78px');

.__input-hint {
    font-size: var(--font-size-sm);
    color: var(--color-muted);
    margin-top: var(--spacer-sm);
}

.u-form-section {
    border: 1px solid var(--color-gray-light);
    padding: var(--spacer-md);
    border-radius: var(--border-radius-lg);
}

@each $key, $value in $size {
    .u-input-size#{$key} {
        .mat-mdc-input-element.mat-mdc-input-element {
            min-height: #{$value};
        }

        .mat-mdc-form-field-flex {
            @if $key == '-md' or $key == '-sm' {
                font-size: var(--font-size-default);
            } @else {
                font-size: var(--font-size#{$key});
            }
        }
    }
}

.u-label-select-input {
    display: none;

    &:not(:disabled) {
        & + .u-label-select-container {
            cursor: pointer;

            * {
                cursor: pointer;
            }
        }
    }

    &:checked {
        & + .u-label-select-container {
            border-color: var(--color-brand-4);
            background: var(--color-gray-medium);

            .__svg,
            app-svg,
            .__icon,
            app-icon,
            .__label,
            label {
                color: var(--color-brand-5);
            }
        }
    }
}

.u-label-select-container {
    border: $common-border-light;
    border-radius: var(--border-radius);
    background: var(--color-white);
}
