@import "../base/mixins";

.u-overflow-y-auto {
    @include overflowYAuto;
}

.u-overflow-x-auto {
    @include overflowXAuto;
}

.u-overflow-hidden {
    overflow: hidden;
}

.u-scroll-container-horizontal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
    //-webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        //display: none;
    }

    > * {
        flex: 0 0 auto;
    }
}
