// =======================================
// Base
// =======================================
@import 'variables';
@import '../../base/mixins';

// =======================================
// Vendors
// =======================================
@import '../../vendors/normalize';
@import 'material';
@import '../../vendors/material-icons';
@import '../../vendors/material';
@import '../../vendors/fortawesome';
@import '../../vendors/quill';
@import '../../vendors/google-maps';
@import '../../vendors/recaptcha';
@import '../../vendors/toastr';

@import '../../base/defaults';

@import '../../utils';
@import '../../modules';

// =======================================
// PROJECT
// =======================================
@import 'buttons';

@import 'roots';
@import 'components/skeleton';

// =======================================
// CDK
// =======================================
@import '@angular/cdk/overlay-prebuilt.css';
