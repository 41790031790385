@use 'sass:map';
@import 'variables';

@mixin noTouchDevice() {
    @media (pointer: fine) {
        @content;
    }
}

@mixin for-size($range) {
    @if $range == phone-portrait {
        @media (max-width: #{$phone-portrait-upper-boundary - 1}) {
            @content;
        }
    } @else if $range == phone-landscape-up {
        @media (min-width: $phone-portrait-upper-boundary) {
            @content;
        }
    } @else if $range == tablet-portrait-up {
        @media (min-width: $phone-landscape-upper-boundary) {
            @content;
        }
    } @else if $range == small-tablet-portrait-up {
        @media (min-width: $large-phone-landscape-upper-boundary) {
            @content;
        }
    } @else if $range == tablet-landscape-up {
        @media (min-width: $tablet-portrait-upper-boundary) {
            @content;
        }
    } @else if $range == desktop-up {
        @media (min-width: $tablet-landscape-upper-boundary) {
            @content;
        }
    } @else if $range == large-desktop-up {
        @media (min-width: $desktop-upper-boundary) {
            @content;
        }
    } @else if $range == big-desktop-up {
        @media (min-width: $big-desktop-upper-boundary) {
            @content;
        }
    }
}

@mixin up-to-and-including-phone-portrait {
    @media (max-width: $phone-portrait-upper-boundary - 1px) {
        @content;
    }
}

@mixin phone-portrait {
    @include for-size(phone-portrait) {
        @content;
    }
}

@mixin up-to-and-including-phone-landscape {
    @media (max-width: $phone-landscape-upper-boundary - 1px) {
        @content;
    }
}

@mixin up-to-and-including-large-phone-landscape {
    @media (max-width: $large-phone-landscape-upper-boundary - 1px) {
        @content;
    }
}

@mixin phone-landscape-up {
    @include for-size(phone-landscape-up) {
        @content;
    }
}

@mixin up-to-and-including-tablet-portrait {
    @media (max-width: $tablet-portrait-upper-boundary - 1px) {
        @content;
    }
}

@mixin tablet-portrait-up {
    @include for-size(tablet-portrait-up) {
        @content;
    }
}

@mixin small-tablet-portrait-up {
    @include for-size(small-tablet-portrait-up) {
        @content;
    }
}

@mixin up-to-and-including-tablet-landscape {
    @media (max-width: $tablet-landscape-upper-boundary - 1px) {
        @content;
    }
}

@mixin tablet-landscape-up {
    @include for-size(tablet-landscape-up) {
        @content;
    }
}

@mixin desktop-up {
    @include for-size(desktop-up) {
        @content;
    }
}

@mixin large-desktop-up {
    @include for-size(large-desktop-up) {
        @content;
    }
}

@mixin big-desktop-up {
    @include for-size(big-desktop-up) {
        @content;
    }
}

@mixin overflowYAuto {
    overflow-y: auto;
    //-webkit-overflow-scrolling: touch;
}

@mixin overflowXAuto {
    overflow-x: auto;
    //-webkit-overflow-scrolling: touch;
}

@mixin dataTable {
    ::ng-deep .mat-mdc-cell {
        font-size: 14px;
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell,
    .mat-mdc-footer-cell {
        padding: 0 1rem;
    }
}

@mixin dataTableWithHover {
    @include dataTable();

    ::ng-deep tbody tr,
    tbody tr {
        cursor: pointer;

        &:hover {
            background: $color-gray-medium !important;
        }
    }
}

@mixin headings {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @content;
    }
}

@mixin bodyText {
    font-family: $font-default;
    font-size: $font-size-default;
    font-weight: 400;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
    color: $color-text;
}

@mixin title {
    margin: 0;
    font-weight: $font-weight-semi-bold;
    font-family: $font-default;
    line-height: inherit;
}

@mixin h5 {
    @include title;
    font-size: 1.25rem;
}

@mixin h6 {
    @include title;
    font-size: 1rem;
}
