@import 'base-colors';
@import 'context-colors';

$scrollbar-width: 17px !default;

//----------------------------------------
// Fonts
//----------------------------------------
$font-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$font-size-default: 16px !default;
$font-size-xs: 0.6875rem !default;
$font-size-sm: 0.75rem !default;
$font-size-sm-alt: 0.875rem !default;
$font-size-md: 1.25rem !default;
$font-size-xm: 1.5rem !default;
$font-size-lg: 1.75rem !default;
$font-size-xl: 2rem !default;
$font-size-xxl: 2.5rem !default;
$font-size-xxxl: 3.5rem !default;

$font-weight-extra-bold: 900 !default;
$font-weight-bold: 700 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-medium: 500 !default;
$font-weight-normal: 400 !default;
$font-weight-thin: 300 !default;

//----------------------------------------
// Line heights
//----------------------------------------

$font-line-height-xs: 1;
$font-line-height-sm: 1.2;
$font-line-height-normal: 1.385;
$font-line-height-lg: 1.6;

//----------------------------------------
// Widths
//----------------------------------------
$spacer: 1rem !default;
$spacer-xxs: 0.125rem !default;
$spacer-xs: 0.25rem !default;
$spacer-sm: 0.5rem !default;
$spacer-md: 1.5rem !default;
$spacer-lg: 2.5rem !default;

$container-extra-extra-small-max-width: 320px !default;
$container-extra-small-max-width: 480px !default;
$container-semi-small-max-width: 512px !default;
$container-small-max-width: 640px !default;
$container-medium-max-width: 800px !default;
$container-max-width: 1024px !default;
$container-large-max-width: 1200px !default;
$container-big-max-width: 1400px !default;

$modal-medium-width: 42rem;
$modal-large-width: $container-max-width;
$modal-big-width: $container-large-max-width;

//----------------------------------------
// Heights
//----------------------------------------
$input-height: 36px !default;

//----------------------------------------
// Commons
//----------------------------------------
$common-border-radius: 4px !default;
$common-border-radius-medium: 6px !default;
$common-border-radius-large: 8px !default;
$common-border-radius-extra-large: 16px !default;
$common-border-radius-extra-extra-large: 20px !default;
$common-border: 2px solid $common-border-color !default;
$common-border-small: 1px solid $common-border-color !default;
$common-border-light: 1px solid $common-border-light-color !default;
$common-border-lightest: 1px solid $common-border-lightest-color !default;
$common-box-shadow:
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05) !default;
$common-box-shadow-dark:
    0 11px 15px -7px #0003,
    0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
$common-box-shadow-tooltip: 0 1px 5px rgba(0, 0, 0, 0.25) !default;

$divider-color: $color-gray-lightest !default;

//----------------------------------------
// Header
//----------------------------------------
$header-height: 4rem !default;
$header-height-tablet-portrait-up: 5rem !default;
$header-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25) !default;

//----------------------------------------
// Side bar
//----------------------------------------
$sidebar-width-open: 240px !default;
$sidebar-width-closed: 78px !default;
$sidebar-main-menu-width: 88px;

//----------------------------------------
// Breakpoints
//----------------------------------------
$phone-portrait-upper-boundary: 480px !default;
$phone-landscape-upper-boundary: 600px !default;
$large-phone-landscape-upper-boundary: 800px !default;
$mat-bottom-sheet-toggle-responsive-boundary: 960px !default;
$tablet-portrait-upper-boundary: 1024px !default;
$tablet-landscape-upper-boundary: 1200px !default;
$desktop-upper-boundary: 1400px !default;
$big-desktop-upper-boundary: 1800px !default;

//----------------------------------------
// Shopping cart
//----------------------------------------
$shopping-cart-height-mobile: 200px !default;
$shopping-cart-height: 150px !default;

//----------------------------------------
// Toggle button
//----------------------------------------
$toggle-button-padding-mobile: 0.5rem 1rem !default;
$toggle-button-padding: 0.5rem 1.5rem !default;

//----------------------------------------
// Button
//----------------------------------------
$button-height-md: 2.375rem !default;
$button-height-sm: 2rem !default;
$button-height-xs: 1.75rem !default;

//----------------------------------------
// Table
//----------------------------------------
$table-row-x-padding: 1.5rem !default;

//----------------------------------------
// Ribbon
//----------------------------------------

$ribbon-width: 9.375rem !default;
$ribbon-height: 9.375rem !default;
$ribbon-overlay-right: -0.375rem !default;
$ribbon-overlay-top: -0.375rem !default;
$ribbon-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !default;
$ribbon-content-width: 15rem !default;
$ribbon-content-height: 3.4375rem !default;
$ribbon-content-padding: 0 3.125rem !default;
$ribbon-content-overlay-left: -1.5625rem !default;
$ribbon-content-overlay-top: 1.875rem !default;

//----------------------------------------
// Bullet
//----------------------------------------

$bullet-size: 8px !default;

//----------------------------------------
// Blog
//----------------------------------------

$blog-post-width: 766px !default;

//----------------------------------------
// Dropdown Menu
//----------------------------------------

$dropdown-menu-panel-padding-y: 8px !default;
$dropdown-menu-item-height: 36px !default;

//----------------------------------------
// Forms
//----------------------------------------
$header-column-width: 13.5rem !default;
$content-column-max-width: 31.25rem !default;

//----------------------------------------
// Arc Score
//----------------------------------------
$arc-score-font-size-xxs: 0.6875rem !default;
$arc-score-font-size-xs: 0.9rem !default;
$arc-score-font-size-sm: 1.2rem !default;
$arc-score-font-size-md: 1.75rem !default;
$arc-score-font-size-lg: 3.5em !default;
$arc-score-font-size-xl: 5.25rem !default;

//----------------------------------------
// Youtube
//----------------------------------------
$youtube-height-sm: 18.75rem !default;
$youtube-height-md: 28.125rem !default;

//----------------------------------------
// Cards
//----------------------------------------
$maps-card-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25) !default;

//----------------------------------------
// Tooltip
//----------------------------------------

$tooltip-padding-x-lg: $spacer-lg !default;
$tooltip-padding-y-lg: $spacer-lg !default;

$tooltip-padding-x-md: $spacer-md !default;
$tooltip-padding-y-md: $spacer-md !default;

$tooltip-padding-x-sm: $spacer-sm !default;
$tooltip-padding-y-sm: $spacer-sm !default;

$tooltip-padding-x-xs: $spacer-xs !default;
$tooltip-padding-y-xs: $spacer-xs !default;

//----------------------------------------
// Opacity
//----------------------------------------

$opacity-transparant: 0 !default;
$opacity-semi-transparant-light: 0.1 !default;
$opacity-semi-transparant: 0.33 !default;
$opacity-half-transparant: 0.5 !default;
$opacity-semi-opaque: 0.66 !default;
$opacity-opaque: 1 !default;

//----------------------------------------
// Svg
//----------------------------------------
$svg-height: 30px !default;

//----------------------------------------
// Bottom Sheet
//----------------------------------------//
$bottom-sheet-min-height: 9.125rem !default;

//----------------------------------------
// Logo - in px, because it's needed for calculation on other places
//----------------------------------------
$logo-mobile-max-width: 32px !default;
$logo-desktop-max-width: 200px !default;

//----------------------------------------
// Language switcher - in px, because it's needed for calculation on other places
//----------------------------------------
$language-switcher-width: 48px !default;
