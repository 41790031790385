//----------------------------------------
// Notification
//----------------------------------------
@import '../base/variables';

.m-notification {
    &.m-notification--error {
        .mdc-snackbar__surface.mdc-snackbar__surface {
            background: $color-error-light;
        }

        .mdc-snackbar__label.mdc-snackbar__label {
            color: $color-error-dark;
        }
    }

    &.m-notification--warning {
        .mdc-snackbar__surface.mdc-snackbar__surface {
            background: $color-accent-light;
        }

        .mdc-snackbar__label.mdc-snackbar__label {
            color: $color-text;
        }
    }

    &.m-notification--success {
        .mdc-snackbar__surface.mdc-snackbar__surface {
            background: $color-success-light;
        }

        .mdc-snackbar__label.mdc-snackbar__label {
            color: $color-text;
        }
    }
}
