@import 'ngx-toastr/toastr';

// Hide messages on mobile, to keep them on screen when switching between resolutions
body > div.overlay-container {
    display: none;

    @include tablet-portrait-up {
        display: block;
    }
}
