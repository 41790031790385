@import 'variables';

:root {
    app-menu-item {
        --menu-item-background-color-hover: var(--color-gray-ultralight);
        --menu-item-background-color-active: var(--color-gray-ultralight);
        --menu-item-text-color: var(--color-gray-dark);
        --menu-item-text-color-hover: var(--menu-item-text-color);
        --menu-item-text-color-active: var(--color-gray-ultradark);
        --menu-item-underline-color: var(--color-gray-ultradark);
        --menu-item-border-radius: var(--border-radius-lg);
    }

    app-previous-submit {
        --previous-submit-background-color: #{$color-secondary-co-branded-hover};
        --previous-submit-border-color: #{$color-secondary-co-branded-hover};
        --previous-submit-text-color: #{$color-white-co-branded};
    }

    --mat-select-enabled-trigger-text-color: $color-gray;
}
