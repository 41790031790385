@import "./u-color";

.u-list-style-initial {
    list-style: initial;
}

.u-list-style-disc {
    list-style: disc;
    padding-left: 1rem;
}

.u-list-style-ordered {
    margin-left: var(--spacer-md);
    counter-reset: li;

    li {
        counter-increment: li;
    }

    li::before {
        content: counter(li);
        display: inline-block;
        width: var(--spacer-md);
        margin-left: calc(-1 * var(--spacer-md));
    }
}

@each $color, $value in map-merge($colors, $context-colors) {
    .u-list-style-color-#{'' + $color} {
        li::before {
            color: #{$value}
        }
    }
}
