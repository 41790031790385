@use 'sass:map';

$context-card-colors: map.merge(
    $context-colors,
    (
        muted: var(--color-gray-lightest),
        danger: var(--color-danger-light),
    )
) !default;


@each $context, $value in map-merge($colors, $context-card-colors) {
    .ui-card-#{'' + $context} {
        background-color: #{$value} !important;

        @if($context == 'default') {}
        @else {
            color: var(--color-white)
        }
    }
}

.ui-card-muted,
.ui-card-danger {
    color: var(--color-text);
}

