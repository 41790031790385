//----------------------------------------
// Utils : Table
//----------------------------------------

@import '../base/variables';
@import '../base/mixins';
@import './u-svg';

%mat-data-table {
    table-layout: fixed;

    tbody tr {
        @include tablet-portrait-up {
            height: 3.125rem;
        }
        height: 4.375rem;
    }

    tr > td:first-of-type,
    tr > th:first-of-type {
        padding-left: $table-row-x-padding;
    }

    tr > td:last-of-type,
    tr > th:last-of-type {
        padding-right: $table-row-x-padding;
    }

    .__row-group {
        td {
            border-bottom: 0;
        }

        td:first-child {
            position: relative;
            &:after {
                @extend %svg-chevron-right;
                content: '';
                position: absolute;
                left: 0.5rem;
                top: 0;
                bottom: 0;
                height: 20px;
                width: 20px;
                margin: auto;
            }
        }

        &.__row-group--open td:first-child:after {
            content: '';
            transform: rotate(90deg);
        }
    }

    .__row--grouped {
        height: 2.5rem;

        td {
            font-size: var(--font-size-sm);
            border-bottom: 0;
        }

        & + :not(.__row--grouped) {
            td {
                border-top-width: 1px;
                border-top-style: solid;
                border-top-color: inherit;
            }
        }
    }

    tr:last-of-type > td {
        border-bottom-width: 0;
    }

    & + mat-paginator {
        border-top: 1px solid var(--color-gray-lightest);
    }
}

.u-table {
    display: table;
}

.u-inline-table {
    display: inline-table;
}

.u-table-row {
    display: table-row;
}

.u-table-cell {
    display: table-cell;
}

.u-data-table,
[mat-table] {
    @extend %mat-data-table;
}

.mat-mdc-table.u-data-table-with-hover {
    @extend %mat-data-table;

    tbody tr {
        cursor: pointer;

        @include noTouchDevice {
            &:hover {
                background: $color-gray-medium;
            }
        }
    }
}

app-card [mat-table] {
    background-color: transparent;

    tr > td:first-of-type,
    tr > th:first-of-type {
        padding-left: var(--spacer-double);
    }
}

:root {
    --table-row-x-padding: #{$table-row-x-padding};
}

.mat-mdc-row.disabled .mat-mdc-cell {
    color: var(--color-muted);
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
    border-bottom-color: var(--color-gray-lightest);
}

.mdc-data-table__cell.__no-ellispis {
    text-overflow: unset;
}

.mat-mdc-header-cell {
    font-size: $font-size-sm;
    color: rgba(0, 0, 0, 54%);
}
