@import '../base/variables';
@import '../base/mixins';

.u-w25p {
    width: 25% !important;
}

.u-w33p {
    width: calc(100% / 3) !important;
}

.u-w40p {
    width: 40% !important;
}

.u-w50p {
    width: 50% !important;
}

.u-w60p {
    width: 60% !important;
}

.u-w75p {
    width: 75% !important;
}

.u-w100p {
    width: 100% !important;
}

.u-w100p-up-to-and-including-phone-landscape {
    @include up-to-and-including-phone-landscape {
        width: 100% !important;
    }
}

.u-w100p-up-to-and-including-tablet-landscape {
    @include up-to-and-including-tablet-landscape {
        width: 100% !important;
    }
}

.u-mw100p {
    max-width: 100% !important;
}

.u-mw50p {
    max-width: 50% !important;
}

.u-mw-none {
    max-width: none !important;
}

.u-mw300 {
    max-width: 300px !important;
}

.u-w25vw {
    width: 25vw;
}

.u-w40vw {
    width: 40vw;
}

.u-w60vw {
    width: 60vw;
}

.u-w80vw {
    width: 80vw;
}

.u-w100vw {
    width: 100vw;
}

.u-w-fit-content {
    width: fit-content;
}

.u-w-max-content {
    width: max-content;
}

.u-w-auto {
    width: auto !important;
}

.u-min-w-0 {
    min-width: 0 !important;
}

.u-min-h-0 {
    min-height: 0 !important;
}
