.m-button {
    position: relative;
    text-decoration: none;
    border-radius: 20px;
    padding: 0.375rem 1rem;
    background: $color-accent-co-branded;
    border: none;
    color: $color-brand-4-co-branded;
    cursor: pointer;
    transition:
        border 150ms linear,
        color 150ms linear,
        background 150ms linear,
        opacity 150ms linear;
    font-weight: 500;
    font-size: inherit;
    line-height: 1.5;

    &.has-icon {
        display: inline-flex;
        align-items: center;

        app-icon {
            margin-left: calc(-1 * var(--spacer-xs));
        }

        span + app-icon,
        app-icon + span {
            margin-left: var(--spacer-sm);
        }

        span + app-icon {
            margin-right: calc(-1 * var(--spacer-xs));
        }
    }

    @media (hover: hover) {
        &:hover,
        &:focus {
            text-decoration: none;

            &:not(:disabled) {
                background: $color-accent-co-branded-hover;
                border: none;
            }
        }
    }

    &:disabled {
        background: $color-accent-co-branded-disabled;
        color: $color-brand-5-co-branded-disabled;
        cursor: not-allowed;

        img {
            opacity: 0.3;
        }
    }
}

.m-button.m-button {
    &--primary {
        background: $color-accent-co-branded;
        border: 1px solid $color-accent-co-branded;
        color: $color-brand-4-co-branded;

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: $color-accent-co-branded-hover;
                    border: 1px solid $color-accent-co-branded-hover;

                    &.m-button--outline {
                        color: $color-brand-4-co-branded !important;
                    }
                }
            }
        }

        &:disabled {
            opacity: 0.3;
        }
    }

    &--secondary {
        background: $color-secondary-co-branded;
        border: 1px solid $color-secondary-co-branded;
        color: $color-white-co-branded;

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    color: $color-white-co-branded;
                    background: $color-secondary-co-branded-hover;
                    border: 1px solid $color-secondary-co-branded-hover;
                }
            }
        }

        &.m-button--outline,
        &.m-button--icon-outline {
            color: $color-secondary-co-branded;
        }

        &:disabled {
            background: $color-secondary-co-branded-disabled;
            border: 1px solid $color-secondary-co-branded-disabled;
            color: $color-white-co-branded;
        }
    }

    &--tertiary {
        background: $color-gray;
        border: 1px solid $color-gray;
        color: $color-white;

        &.m-button--outline {
            color: $color-gray;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: $color-gray-dark;
                    border: 1px solid $color-gray-dark;
                }
            }
        }

        &.m-button--outline,
        &.m-button--icon-outline {
            color: $color-gray;
        }

        &:disabled {
            background: $color-gray-light;
            border: 1px solid $color-gray-light;
            color: $color-white;
        }
    }

    &--link,
    &--link-reversed {
        @extend .u-link;
        text-decoration: none;
        background: transparent;
        border: 0;
        padding-left: 0;
        padding-right: 0;

        span {
            text-decoration: underline;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: transparent;
                    border: 0;

                    span {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &--link-reversed {
        span {
            text-decoration: none;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &--icon {
        background: transparent;
        border: 0;
        padding: 0;
        opacity: 0.8;
        justify-content: center;

        app-icon {
            position: unset;
            margin-left: unset;
        }

        span {
            display: none;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: transparent;
                    border: 0;
                    opacity: 1;
                }
            }
        }

        &:disabled {
            background: 0;
            border: 0;
        }
    }

    &--danger {
        background: $color-error;
        border: 1px solid $color-error;
        color: $color-white;

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: $color-error-dark;
                    border: 1px solid $color-error-dark;
                }
            }
        }

        &:disabled {
            background: $color-error-light;
            border: 1px solid $color-error-light;
            color: $color-white;
        }
    }

    &--no-style {
        background: transparent;
        border: none;
        color: inherit;
        padding: 0;
        border-radius: 0;

        &:hover,
        &:focus {
            background: transparent;
            border: none;
            color: inherit;

            &:not(:disabled) {
                background: transparent;
                border: none;
                color: inherit;
            }
        }

        &.disabled {
            color: var(--color-muted) !important;
            cursor: not-allowed;
        }
    }

    &--outline {
        @extend .m-button--no-style;
        background: transparent;
        border: 1px solid currentColor;
        border-style: solid;
        &:disabled {
            opacity: 0.3;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    border: 1px solid currentColor;
                }
            }
        }
    }

    &--icon-outline {
        overflow: hidden;
        background-color: $color-white;
        justify-content: center;
        height: 38px;
        width: 38px;
        padding: 0;
        border-radius: 50%;

        app-icon {
            margin-left: unset;
        }

        span {
            display: none;
        }

        &.m-button--sm {
            width: 28px;
            height: 28px;
        }
    }

    &--circle {
        padding: 0;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
    }

    &--logo-button {
        background: transparent;
        border: none;
        color: $color-text;
        padding: 0.5rem;
        border-radius: $common-border-radius;

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: $color-gray-medium;
                    border: none;
                    color: $color-text;
                }
            }
        }
    }

    &--icon-circle {
        width: 28px;
        height: 28px;
        padding: 0;
        border: 1px solid $color-secondary-co-branded;
        color: $color-secondary-co-branded;
        background: transparent;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        line-height: initial;

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: $color-secondary-co-branded-hover;
                    color: $color-white-co-branded;
                    border: 1px solid $color-secondary-co-branded-hover;
                }
            }
        }

        svg {
            width: 18px;
        }
    }

    &--icon-circle-gray {
        width: 24px;
        height: 24px;
        padding: 0;
        color: $color-white;
        background: $color-gray;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-size: 0.875rem;

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: $color-gray-dark;
                }
            }
        }

        svg {
            width: 18px;
        }
    }

    &--secondary-outline,
    &--secondary.m-button--outline {
        background: transparent;
        color: $color-secondary-co-branded;
        border: 1px solid $color-secondary-co-branded;

        @media (hover: hover) {
            &:hover,
            &:focus {
                &:not(:disabled) {
                    background: $color-secondary-co-branded-hover;
                    color: $color-white-co-branded;
                    border: 1px solid $color-secondary-co-branded-hover;
                }
            }
        }

        &:disabled {
            opacity: 0.3;
        }
    }
}

.m-button + .m-button {
    margin-left: var(--spacer-default);
}
