@import '../base/variables';

.m-tag {
    display: inline-flex;
    padding: var(--spacer-xs) var(--spacer-sm);
    border-radius: var(--border-radius);

    &--success {
        background: $tag-success-background;
        color: $tag-success-color;
    }

    &--info {
        background: $tag-info-background;
        color: $tag-info-color;
    }

    &--error {
        background: $tag-error-background;
        color: $tag-error-color;
    }
}
