//----------------------------------------
// Utils : Vertical align
//----------------------------------------

.u-vertical-align-top {
    vertical-align: top;
}

.u-vertical-align-middle {
    vertical-align: middle;
}

.u-vertical-align-bottom {
    vertical-align: bottom;
}
