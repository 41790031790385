@import '../base/variables';

$shadow-collection: (
    ribbon: $ribbon-shadow,
    box: $common-box-shadow,
    tooltip: $common-box-shadow-tooltip,
    maps-card: $maps-card-shadow,
    header: $header-shadow,
);

@each $key, $value in $shadow-collection {
    .u-shadow-#{$key} {
        box-shadow: #{$value};
    }
}

:root {
    @each $key, $value in $shadow-collection {
        --shadow-#{$key}: #{$value};
    }
}
