.ui-collapsible {
    cursor: pointer;

    &.ui-collapsible-can-collapse {
        transition: all 0.3s ease;
        white-space: nowrap;

        &::before {
            content: '';
            border-right: 2px solid currentColor;
            border-bottom: 2px solid currentColor;
            width: 0.5em;
            height: 0.5em;
            transform: rotate(45deg);
            display: inline-block;
            vertical-align: middle;
            margin-right: 1rem;
            transition: all 0.3s ease;
        }

        &.collapsed::before {
            transform: rotate(-45deg);
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
