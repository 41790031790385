//----------------------------------------
// Utils : Text
//----------------------------------------

@import '../base/variables';
@import '../base/mixins';
@import '../vendors/cera-pro';

/* Text align */
$position-collection: (-center: 'center', -left: 'left', -right: 'right');

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: $font-line-height-normal;
}

@each $key, $value in $position-collection {
    .u-text-align#{$key} {
        text-align: #{$value} !important;
    }

    .u-text-align#{$key}-up-to-and-including-phone-landscape {
        @include up-to-and-including-phone-landscape {
            text-align: #{$value} !important;
        }
    }

    .u-text-align#{$key}-tablet-portrait-up {
        @include tablet-portrait-up {
            text-align: #{$value} !important;
        }
    }
}

.u-font-size-12 {
    font-size: 12px;
}

.u-font-size-13 {
    font-size: 13px;
}

.u-font-size-14 {
    font-size: 14px;
}

.u-font-size-16 {
    font-size: 16px;
}

.u-font-size-18 {
    font-size: 18px;
}

.u-font-size-20 {
    font-size: 20px;
}

.u-font-size-22 {
    font-size: 22px;
}

.u-font-size-24 {
    font-size: 24px;
}

.u-font-size-26 {
    font-size: 26px;
}

.u-font-size-28 {
    font-size: 28px;
}

/* Titles */
.u-title-responsive {
    margin-bottom: $spacer;
    @include tablet-landscape-up {
        margin-bottom: $spacer * 2;
    }
}

/* Others */
.u-text-underlined {
    text-decoration: underline;
}

.u-text-decoration-none {
    text-decoration: none;
}

.u-text-line-through {
    text-decoration: line-through;
}

.u-white-space-initial {
    white-space: initial;
}

.u-white-space-pre-wrap {
    white-space: pre-wrap;
}

.u-white-space-no-wrap {
    white-space: nowrap;
}

.u-white-space-break-spaces {
    white-space: break-spaces;
}

.u-text-accent {
    background: $color-accent;
    color: $color-white;
    padding: 1px 4px;
}

.u-text-lead {
    @extend .u-font-size-20;
    color: var(--color-text);
    line-height: $font-line-height-lg;
}

.u-text-truncate,
.u-text-truncate span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.u-text-truncate span {
    display: block !important; // make sure display is always block, otherwise truncate will not work
}

.u-pre-line {
    white-space: pre-line;
}

.u-cursor-not-allowed {
    cursor: not-allowed;
}

// disabled styled always overrules other styles
.u-disabled {
    pointer-events: none !important;
    color: $color-gray-light !important;
    opacity: 0.7 !important;
}

.u-disabled-with-pointer {
    color: $color-gray-light !important;
    opacity: 0.7 !important;
}

.u-line-height-0 {
    line-height: 0;
}

.u-line-height-1 {
    line-height: 1;
}

.u-line-height-normal {
    line-height: normal;
}

.u-line-height-default {
    line-height: 1.5 !important;
}

.u-text-uppercase {
    text-transform: uppercase;
}

.u-font-style-italic {
    font-style: italic;
}

.u-dot-after::after {
    content: '\2022';
    display: inline-block;
    margin: 0 var(--spacer-sm);
}

$font-size-collection: (
    xs: $font-size-xs,
    sm: $font-size-sm,
    sm-alt: $font-size-sm-alt,
    default: $font-size-default,
    md: $font-size-md,
    xm: $font-size-xm,
    lg: $font-size-lg,
    xl: $font-size-xl,
    xxl: $font-size-xxl,
    xxxl: $font-size-xxxl,
    inherit: inherit,
);

// legacy font-size classes
$font-size-alternative-key-collection: (
    xs: 'extra-small',
    sm: 'small',
    md: 'medium',
    xm: 'extra-medium',
    lg: 'large',
);

$font-line-height-collection: (
    xs: $font-line-height-xs,
    sm: $font-line-height-sm,
    normal: $font-line-height-normal,
    lg: $font-line-height-lg,
);

$font-size-line-height-collection: (
    xs: $font-line-height-normal,
    sm: $font-line-height-normal,
    default: inherit,
    inherit: inherit,
);

$font-weight-collection: (
    extra-bold: $font-weight-extra-bold,
    bold: $font-weight-bold,
    semi-bold: $font-weight-semi-bold,
    medium: $font-weight-medium,
    thin: $font-weight-thin,
    normal: $font-weight-normal,
);

:root {
    /* Font size */
    $previousSize: '';
    @each $size, $value in $font-size-collection {
        --font-size-#{$size}: #{$value};

        $alt-key-size: map-get($font-size-alternative-key-collection, $size);
        $line-height: map-get($font-size-line-height-collection, $size);

        $alt-key-size: if($alt-key-size, $alt-key-size, $size);
        $line-height: if($line-height, $line-height, 1);

        .u-font-size-#{'' + $size},
        .u-font-size-#{'' + $alt-key-size} {
            font-size: var(--font-size-#{$size});
            line-height: #{$line-height};
        }

        .u-font-size-#{'' + $size}-up-to-and-including-phone-landscape {
            @include up-to-and-including-phone-landscape {
                font-size: var(--font-size-#{$size});
                line-height: #{$line-height};
            }
        }

        .u-font-size-#{'' + $size}-tablet-portrait-up {
            @include tablet-portrait-up {
                font-size: var(--font-size-#{$size});
                line-height: #{$line-height};
            }
        }

        @if $size != 'sm-alt' {
            .u-font-size-#{'' + $previousSize}-larger {
                font-size: var(--font-size-#{$size});
                line-height: #{$line-height};
            }

            .u-font-size-#{'' + $previousSize}-larger-up-to-and-including-phone-landscape {
                @include up-to-and-including-phone-landscape {
                    font-size: var(--font-size-#{$size});
                    line-height: #{$line-height};
                }
            }

            .u-font-size-#{'' + $previousSize}-larger-tablet-portrait-up {
                @include tablet-portrait-up {
                    font-size: var(--font-size-#{$size});
                    line-height: #{$line-height};
                }
            }
        }

        // reset to sm
        $previousSize: if('sm-alt', 'sm', $size);
    }

    /* Font weight */
    @each $weight, $value in $font-weight-collection {
        --font-weight-#{$weight}: #{$value};
        .u-font-weight-#{$weight} {
            font-weight: var(--font-weight-#{$weight}) !important;
        }
    }

    @each $weight, $value in $font-weight-collection {
        --font-weight-#{$weight}: #{$value};
        .u-font-weight-#{$weight} {
            font-weight: var(--font-weight-#{$weight}) !important;
        }
    }

    @each $key, $value in $font-line-height-collection {
        --line-height-#{$key}: #{$value};
        .u-font-line-height-#{$key} {
            line-height: var(--line-height-#{$key}) !important;
        }
    }
}

.u-text-blurred {
    color: transparent !important;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

@mixin smvd-ui-marked-text-theme($context, $marked-text-map) {
    $_background: map-get($marked-text-map, background);
    $_color: map-get($marked-text-map, color);
    $_font-weight: map-get($marked-text-map, font-weight);
    $_font-style: map-get($marked-text-map, font-weight);

    .u-marked-text-#{'' + $context} {
        padding: 2px 10px 2px;
        border-radius: 2rem;
        background-color: $_background;
        color: $_color;
        font-weight: $_font-weight;
        font-style: $_font-style;
    }
}

$marked-text-primary-map: (
    background: $color-primary,
    color: $color-white,
    font-weight: $font-weight-normal,
    font-style: italic,
) !default;

$marked-text-tertiary-map: (
    background: $color-tertiary,
    color: inherit,
    font-weight: $font-weight-semi-bold,
    font-style: inherit,
) !default;

@include smvd-ui-marked-text-theme('primary', $marked-text-primary-map);
@include smvd-ui-marked-text-theme('tertiary', $marked-text-tertiary-map);

.u-font-family-cera-pro {
    font-family: $use-font-cera-pro;
}
