@use '@angular/material' as mat;
$candy-app-primary: mat.define-palette($palette-primary);
$candy-app-accent: mat.define-palette($palette-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette($red-palette);

$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
    $font-family: $font-default,
    $headline-5: mat.define-typography-level($font-size-xxl, 1.5, 700, $font-default, normal),
    $body-2: mat.define-typography-level($font-size-default, 1.5, 400, $font-default, normal),
    $body-1: mat.define-typography-level($font-size-default, 1.5, 400, $font-default, normal),
    $button: mat.define-typography-level($font-size-default, 1.5, 400, $font-default, normal),
);

@include mat.all-component-typographies($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);

// Input overrides
textarea {
    &.mat-mdc-input-element.mat-mdc-input-element {
        padding: 0.5rem 0;
    }
}

.mat-mdc-input-element.mat-mdc-input-element {
    margin: 0;
    min-height: 44px;
}

.mat-mdc-input-element:read-only {
    color: $color-gray;
}

.mat-mdc-form-field,
.mat-mdc-form-field-error {
    position: relative;
}

.mdc-text-field--outlined.mdc-text-field--outlined {
    padding-left: 0;
    padding-right: 0;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay {
    background: transparent;
}

.mat-mdc-form-field-hint {
    color: $color-gray;
    font-size: var(--font-size-xs);
}

.mat-mdc-form-field ~ .mat-mdc-form-field-hint {
    padding: 0.25rem 0.75rem 0;
}

.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-subscript-wrapper,
app-check-input mat-error {
    top: auto;
    bottom: -2px;
    font-size: 70%;
    position: absolute;
    pointer-events: none;
}

.mat-mdc-form-field-infix.mat-mdc-form-field-infix {
    border: none;
    padding: 1px 0;
    min-height: auto;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 0px;
    padding-bottom: 0px;
}

.mat-mdc-form-field-appearance-standard.mat-mdc-form-field-appearance-standard {
    .mat-mdc-text-field-wrapper.mat-mdc-text-field-wrapper {
        padding: 0;
    }

    .mat-mdc-form-field-flex.mat-mdc-form-field-flex {
        padding-top: 0;
        align-items: center;
    }

    .mat-mdc-form-field-underline {
        display: none;
    }
}

.mat-form-field-appearance-outline.mat-form-field-appearance-outline {
    &.mat-form-field-invalid {
        .mdc-text-field--outlined.mdc-text-field--outlined {
            &.mat-mdc-form-field-outline-thick.mat-mdc-form-field-outline-thick {
                color: $color-error;
            }
        }
    }

    &:not(.mat-form-field-invalid):not(.mat-focused) {
        .mat-mdc-form-field-flex.mat-mdc-form-field-flex {
            &:hover {
                .mat-mdc-form-field-outline.mat-mdc-form-field-outline {
                    opacity: 1;

                    &.mat-mdc-form-field-outline-thick.mat-mdc-form-field-outline-thick {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .mat-mdc-select-arrow-wrapper.mat-mdc-select-arrow-wrapper {
        transform: none;
    }

    .mat-mdc-form-field-wrapper.mat-mdc-form-field-wrapper {
        padding: 0;
        margin: 0;
    }

    .mat-mdc-form-field-flex.mat-mdc-form-field-flex {
        padding: 0 0.75em 0 0.75em;
        margin: 0;
        align-items: center;
    }

    .mat-mdc-form-field-outline.mat-mdc-form-field-outline {
        top: 0;
        color: $color-gray-light;

        &.mat-mdc-form-field-outline-thick.mat-mdc-form-field-outline-thick {
            color: $color-secondary;

            .mat-mdc-form-field-outline-start.mat-mdc-form-field-outline-start,
            .mat-mdc-form-field-outline-end.mat-mdc-form-field-outline-end {
                border-width: 1px;
            }
        }
    }

    .mat-mdc-form-field-outline-start.mat-mdc-form-field-outline-start {
        border-radius: $common-border-radius 0 0 $common-border-radius;
        min-width: $common-border-radius;
    }

    .mat-mdc-form-field-outline-end.mat-mdc-form-field-outline-end {
        border-radius: 0 $common-border-radius $common-border-radius 0;
    }

    .mat-mdc-form-field-text-suffix.mat-mdc-form-field-text-suffix {
        top: 0;
    }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 1px;
    padding-bottom: 1px;
}

// Button overrides
.mat-mdc-button-focus-overlay.mat-mdc-button-focus-overlay {
    display: none;
}

.mat-mdc-button.mat-mdc-button,
.mat-mdc-unelevated-button.mat-mdc-unelevated-button,
.mat-mdc-raised-button.mat-mdc-raised-button,
.mat-mdc-outlined-button.mat-mdc-outlined-button {
    border-radius: 9999px;
    line-height: 1.2;
    padding: 0.5rem 1rem;

    &:disabled {
        cursor: not-allowed;
    }

    &.--disabled {
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
    }
}

.mat-mdc-icon-button.mat-mdc-icon-button {
    white-space: normal;

    &:disabled {
        cursor: not-allowed;
    }

    &.--disabled {
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
    }
}

.mat-mdc-unelevated-button.mat-mdc-unelevated-button {
    &.mat-accent {
        transition: all 100ms linear;
        color: $color-brand-4;

        &:not(:disabled) {
            &:hover {
                background: $color-brand-1;
                color: $color-white;
            }
        }
    }
}

.mat-mdc-checkbox-inner-container.mat-mdc-checkbox-inner-container {
    margin: 4px 0.5rem 0 0;
}

.mat-mdc-checkbox-layout.mat-mdc-checkbox-layout {
    align-items: flex-start;
    white-space: normal;
}

.mat-mdc-checkbox-label.mat-mdc-checkbox-label {
    line-height: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.mat-mdc-checkbox.mat-mdc-checkbox {
    .mdc-form-field.mdc-form-field {
        align-items: flex-start;
    }

    .mdc-checkbox.mdc-checkbox {
        padding: 0 4px 0 0;
        margin: 2px 0 0;

        .mdc-checkbox__native-control.mdc-checkbox__native-control {
            width: 16px;
            height: 16px;
        }

        .mdc-checkbox__background.mdc-checkbox__background {
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
        }
    }

    .mat-mdc-checkbox-touch-target.mat-mdc-checkbox-touch-target {
        width: 26px;
        height: 26px;
    }

    &:not(.mat-mdc-checkbox-disabled) {
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
            ~ .mdc-checkbox__background.mdc-checkbox__background {
            border-color: $color-gray-light;
        }
    }
}

.mat-mdc-checkbox-checked.mat-mdc-checkbox-checked,
.mat-mdc-checkbox.mat-mdc-checkbox .mdc-checkbox:hover {
    &:not(.mat-mdc-checkbox-disabled) {
        .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
            background-color: $material-checkbox-checked-background-color;
            border-color: $material-checkbox-checked-background-color;
        }

        .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
            color: #fafafa;
        }
    }

    &.mat-mdc-checkbox-disabled {
        .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
            background: $color-disabled;
            border-color: $color-gray-light;
        }
    }
}

.mat-mdc-checkbox-disabled {
    .mat-mdc-checkbox-inner-container {
        background-color: $color-gray-medium;
    }
}

.mat-mdc-select-panel.mat-mdc-select-panel {
    &.mdc-menu-surface.mdc-menu-surface {
        padding: 0;
        min-width: 60px;
    }

    .mat-mdc-option.mat-mdc-option {
        padding: 12px 16px;
        &:not(.mat-mdc-option-disabled) {
            color: $color-text;
        }

        &.mat-mdc-option-disabled {
            color: $color-disabled;
        }
    }

    &.ignore-disabled {
        .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-checked {
            background: $color-accent !important; // override disabled state
        }

        .mat-mdc-option.mat-mdc-option {
            color: $color-text;
        }
    }
}

/**
Button groups
 */

.mat-button-toggle-group-appearance-standard {
    @include phone-portrait {
        .mat-button-toggle {
            display: flex;
            flex-grow: 1;
        }
    }
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-group-appearance-standard {
    border-radius: 0;
    border: none;

    .mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard {
        &:first-of-type {
            border-radius: 9999px 0 0 9999px;

            .mat-button-toggle-button.mat-button-toggle-button {
                border-radius: 9999px 0 0 9999px;
            }
        }

        &:last-of-type {
            border-radius: 0 9999px 9999px 0;

            .mat-button-toggle-button.mat-button-toggle-button {
                border-radius: 0 9999px 9999px 0;
            }
        }
    }

    &.is-secondary {
        .mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard {
            border-top: $common-border-light;
            border-bottom: $common-border-light;

            &:first-of-type {
                border-left: $common-border-light;
            }

            &:last-of-type {
                border-right: $common-border-light;
            }

            &:hover {
                background: $color-gray-light;
                color: $color-text;
            }

            &.mat-button-toggle-checked {
                &:not(:hover) {
                    background: $color-gray;
                    color: $color-white;
                    border-top: 1px solid $color-gray;
                    border-bottom: 1px solid $color-gray;

                    &:first-of-type {
                        border-left: 1px solid $color-gray;
                    }

                    &:last-of-type {
                        border-right: 1px solid $color-gray;
                    }
                }
            }

            & + .mat-button-toggle-appearance-standard {
                border-left: $common-border-light;
            }
        }
    }

    &:not(.is-secondary) {
        .mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard {
            border-top: 1px solid $material-button-toggle-standard-border-color;
            border-bottom: 1px solid $material-button-toggle-standard-border-color;

            &:first-of-type {
                border-left: 1px solid $material-button-toggle-standard-border-color;
            }

            &:last-of-type {
                border-right: 1px solid $material-button-toggle-standard-border-color;
            }

            &:hover {
                background: $material-button-toggle-standard-background-color;
                color: $material-button-toggle-standard-text-color;
            }

            &.mat-button-toggle-checked {
                &:not(:hover) {
                    background: $material-button-toggle-checked-background-color;
                    color: $material-button-toggle-checked-text-color;
                    border-top: 1px solid $material-button-toggle-checked-border-color;
                    border-bottom: 1px solid $material-button-toggle-checked-border-color;
                    border-left: 1px solid $material-button-toggle-checked-border-color;
                    border-right: 1px solid $material-button-toggle-checked-border-color;
                }
            }

            & + .mat-button-toggle-appearance-standard {
                border-left: $common-border-light;
            }
        }
    }

    .mat-button-toggle-appearance-standard.mat-button-toggle-appearance-standard {
        font-weight: 500;
    }

    .mat-button-toggle-button.mat-button-toggle-button {
        @include up-to-and-including-phone-landscape {
            padding: $toggle-button-padding-mobile;
        }

        @include tablet-portrait-up {
            padding: $toggle-button-padding;
        }

        background: transparent;

        &:hover {
            border: 0;
            background: transparent;
            color: $color-text;
        }
    }

    .mat-button-toggle-label-content.mat-button-toggle-label-content {
        padding: 0;
        line-height: 1.5;
    }
}

.mat-datepicker-toggle.mat-datepicker-toggle {
    color: $color-text;

    &.mat-datepicker-toggle-active.mat-datepicker-toggle-active {
        color: $color-text;
    }

    button {
        &:hover {
            background: transparent;
            border: none;
        }
    }
}

.mat-calendar-previous-button.mat-calendar-previous-button,
.mat-calendar-next-button.mat-calendar-next-button,
.mat-calendar-period-button.mat-calendar-period-button {
    background: transparent;
    border: none;

    &:hover {
        background: transparent;
        border: none;
    }
}

.mat-datepicker-content.mat-datepicker-content {
    .mat-mdc-button-ripple.mat-mdc-button-ripple {
        display: none;
    }
}

.mat-calendar-body-selected.mat-calendar-body-selected {
    background: $material-calendar-selected-background-color;
}

.mat-mdc-progress-spinner.mat-mdc-progress-spinner circle {
    stroke: $color-brand-5;
}

.mat-mdc-radio-ripple.mat-mdc-radio-ripple,
.mat-mdc-checkbox-ripple.mat-mdc-checkbox-ripple {
    display: none;
}

.mat-mdc-radio-button.mat-mdc-radio-button {
    &.mat-mdc-radio-checked.mat-mdc-radio-checked {
        .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle.mdc-radio__outer-circle,
        .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle.mdc-radio__inner-circle {
            border-color: $material-radio-border-color;
        }
    }

    .mdc-radio.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
        border-color: $color-gray-light;
    }
}

.mat-mdc-radio-button.mat-mdc-radio-button .mdc-radio.mdc-radio {
    padding: 0px 4px 0px 0px;
}

.mdc-radio__outer-circle.mdc-radio__outer-circle {
    border-color: $color-gray-light;
}

.mat-mdc-snack-bar-container.mat-mdc-snack-bar-container {
    border-radius: $common-border-radius;
    box-shadow: none;
}

.mat-mdc-tab-nav-bar.mat-mdc-tab-nav-bar {
    .mat-ink-bar.mat-ink-bar {
        background-color: $material-tab-nav-background-color;
    }
}

.mat-mdc-tooltip.mat-mdc-tooltip {
    z-index: 9999;
}

.cdk-global-scrollblock.cdk-global-scrollblock {
    position: static;
}

.mat-badge {
    .mat-badge-content {
        font-variant: small-caps;
        font-size: 11px;
        width: auto;
        min-width: 19px;
        height: 19px;
        line-height: 19px;
    }

    &.mat-badge-wide {
        .mat-badge-content {
            width: auto;
            padding: 0 var(--spacer-sm);
            border-radius: $common-border-radius-extra-large;
        }
    }

    &.badge-vertical-middle .mat-badge-content {
        position: relative;
        top: 0 !important;
        bottom: 0 !important;
    }

    &.badge-vertical-top .mat-badge-content {
        position: relative;
        right: 4px !important;
        top: -4px !important;
        bottom: unset !important;
    }
}

:not([app-button]).mat-badge.mat-badge-wide.mat-badge-after:not(.badge-vertical-middle):not(.badge-vertical-top) .mat-badge-content {
    transform: translateX(100%);
}

.mat-tab-group-theme {
    &.mat-tab-group-theme--pill {
        .mat-mdc-tab-header {
            border: none;
            margin: 1rem 0;
        }
    }
}

.mat-mdc-chip.mat-accent {
    background: $color-accent;
    color: $color-white;
}

.mat-mdc-chip.mat-mdc-chip-size-sm {
    font-size: var(--font-size-xs);
    min-height: 1.5rem;
}

.mat-mdc-chip.mat-mdc-chip-size-xs {
    font-size: var(--font-size-xs);
    min-height: 1.125rem;
    padding: 0px 6px;
}

.mat-mdc-form-field-prefix {
    top: 0 !important;
}

div.mat-mdc-menu-panel {
    min-height: 2 * $dropdown-menu-panel-padding-y + $dropdown-menu-item-height;

    .mat-mdc-menu-content:not(:empty) {
        padding-top: $dropdown-menu-panel-padding-y;
        padding-bottom: $dropdown-menu-panel-padding-y;
    }

    .mat-mdc-menu-content.mat-mdc-menu-content .mat-mdc-menu-item.mat-mdc-menu-item {
        line-height: $dropdown-menu-item-height;
        min-height: $dropdown-menu-item-height;

        app-icon {
            min-width: var(--spacer-md);
        }

        app-icon + span {
            margin-left: var(--spacer-xs);
        }
    }
}

.mat-mdc-slide-toggle.mat-checked {
    .mat-mdc-slide-toggle-thumb {
        background-color: $material-slide-toggle-thumb;
    }

    .mat-mdc-slide-toggle-bar {
        background-color: $material-slide-toggle-bar;
    }
}

div.mat-mdc-menu-panel.mat-big-panel {
    max-width: unset;
}

/* Dialog */
.mat-mdc-dialog-container .mdc-dialog__title {
    &::before {
        display: none;
    }
}

/** Table **/

/** Paginator **/

.mat-mdc-paginator-container {
    .mdc-text-field--outlined .mdc-notched-outline {
        display: none; // remove border of page size dropdown
    }

    .mat-mdc-select-trigger.mat-mdc-select-trigger {
        max-height: 24px;
        line-height: 24px;
        border-bottom: 1px solid var(--color-text);
    }
}

// Remove the ripple for the checkbox and switch
.mdc-checkbox__ripple.mdc-checkbox__ripple,
.mdc-switch__ripple.mdc-switch__ripple {
    display: none;
}

// Remove the ripple for the radio button
.mat-mdc-radio-button.mat-accent {
    --mat-mdc-radio-ripple-color: transparent;
    --mat-mdc-radio-checked-ripple-color: transparent;
}

// INPUT FIELDS
.mat-form-field-appearance-outline.mat-form-field-appearance-outline,
.mdc-notched-outline.mdc-notched-outline {
    .mdc-text-field--outlined.mdc-text-field--outlined {
        opacity: 1 !important;

        &:not(.mdc-text-field--disabled, .mdc-text-field--invalid) {
            .mdc-notched-outline__leading.mdc-notched-outline__leading,
            .mdc-notched-outline__notch.mdc-notched-outline__notch,
            .mdc-notched-outline__trailing.mdc-notched-outline__trailing {
                border-color: $color-gray-light;
            }

            &.mdc-text-field--focused.mdc-text-field--focused {
                .mdc-notched-outline__leading.mdc-notched-outline__leading,
                .mdc-notched-outline__notch.mdc-notched-outline__notch,
                .mdc-notched-outline__trailing.mdc-notched-outline__trailing {
                    border-color: $color-secondary;
                    border-width: 1px;
                }
            }
        }
    }
}
