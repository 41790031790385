%animated-scrollbar {
    --animated-scrollbar-spacer: 0;
    margin-right: calc(-1 * var(--animated-scrollbar-spacer)) !important;
    padding-right: var(--animated-scrollbar-spacer) !important;
    transition: width ease 0.1s;

    @include tablet-portrait-up {
        margin-right: calc(calc(-1 * var(--animated-scrollbar-spacer)) - #{$scrollbar-width}) !important;
        padding-right: calc(calc(var(--animated-scrollbar-spacer)) + #{$scrollbar-width}) !important;

        &:hover {
            margin: 0 calc(-1 * var(--animated-scrollbar-spacer)) 0 !important;
            padding: 0 var(--animated-scrollbar-spacer) 0 !important;
        }
    }
}

%animated-scrollbar-always-visible {
    @extend %animated-scrollbar;

    @include tablet-portrait-up {
        margin: 0 calc(-1 * var(--animated-scrollbar-spacer)) 0 !important;
        padding: 0 var(--animated-scrollbar-spacer) 0 !important;
    }
}

.u-animated-scrollbar {
    @extend %animated-scrollbar;
}

.u-custom-scrollbar {
    @extend %animated-scrollbar;
    &::-webkit-scrollbar {
        width: 16px;
        background-color: #fff;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;

        &:hover {
            background-color: #f4f4f4;
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0);
        border-radius: 16px;
        border: 5px solid #fff;
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: #a0a0a5;

        &:hover {
            background-color: #a0a0a5;
            border: 4px solid #f4f4f4;
        }
    }
}

.u-custom-scrollbar-always-visible {
    @extend %animated-scrollbar-always-visible;
    &::-webkit-scrollbar {
        width: 16px;
        background-color: #fff;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;

        &:hover {
            background-color: #f4f4f4;
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a0a0a5;
        border-radius: 16px;
        border: 5px solid #fff;
    }

    &:hover::-webkit-scrollbar-thumb {
        &:hover {
            background-color: #a0a0a5;
            border: 4px solid #f4f4f4;
        }
    }
}


