@import '../base/variables';

.m-alert {
    position: relative;
    padding: var(--spacer-sm-md) var(--spacer-sm-md);
    margin-bottom: var(--spacer-default);
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    width: 100%;
    height: 100%;
}

.m-alert a {
    font-weight: 700;
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}

.m-alert {
    background: var(--color-gray-lightest);
    color: var(--color-brand-5);
    border-color: var(--color-gray-light);
}

.m-alert--success {
    background: $color-card-success;
    color: var(--color-brand-5);
    border-color: $color-card-success-border;
}

.m-alert--warning {
    background: var(--color-warning-light);
    color: var(--color-brand-5);
    border-color: var(--color-warning);
}

.m-alert--info {
    background: var(--color-info-light);
    color: var(--color-info-darkest);
    border-color: var(--color-info);
}

.m-alert--danger {
    background: var(--color-danger-light);
    color: var(--color-danger-dark);
    border-color: var(--color-danger);
}

.m-alert--muted {
    background: var(--color-gray-ultralight);
    color: var(--color-gray-ultradark);
    border-color: var(--color-gray-lightest);
}

.m-alert--text {
    background: transparent;
    color: var(--color-danger);
    border: none;
    padding: 0;
    margin-bottom: 0;

    a {
        color: var(--color-danger-dark);
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;

        :hover {
            text-decoration: none;
        }
    }
}
