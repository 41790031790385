// hide google maps footer features
.gmnoprint, .gm-style-cc {
    display: none;
}

.pac-container {
    @extend .mat-elevation-z4; // Same as mat-menu-panel
    margin-top: var(--spacer-sm);
    min-width: 15.625rem !important;
    max-width: 100vw;
    border-radius: var(--border-radius);
    border-top: 0;

    &:after {
        // Google logo should not be removed: https://mapsplatform.googleblog.com/2012/09/google-places-autocomplete-now-includes.html
        margin-right: var(--spacer-sm);
        margin-bottom: var(--spacer-xs);
    }
}

