$rotate-angles: (
    '': 0deg,
    '-45': 45deg,
    '-90': 90deg,
    '-135': 135deg,
    '-180': 180deg,
    '-225': 225deg,
    '-270': 270deg,
    '-315': 315deg,
);

@each $key, $value in $rotate-angles {
    .u-rotate#{$key} {
        transform: rotate(#{$value});
    }
}

.u-mirror-x {
    transform: scaleX(-1);
}
